// plugins/TransportationRatesPlugin.js

export default {
    namespaced: true,
    state: {
      formData: {
        calendarName: '',
        startDate: '',
        endDate: '',
        selectedZones: [],
        selectedVehicles: [],
        selectedDates: [],
        vehicleRates: {},
        // ... otras propiedades que esperas utilizar ...
      },
    },
    mutations: {
      SET_FORM_DATA(state, data) {
        state.formData = data;
      },
      UPDATE_FORM_DATA(state, payload) {
        state.formData = { ...state.formData, ...payload };
      },
      SET_VEHICLE_RATES(state, rates) {
        state.formData.vehicleRates = rates;
      },
      SET_SELECTED_ZONES(state, zones) {
        state.formData.selectedZones = zones;
      },
      CLEAR_VEHICLE_RATES(state) {
        state.formData.vehicleRates = {};
      },
      CLEAR_SELECTED_ZONES(state) {
        state.formData.selectedZones = [];
      },
      CLEAR_SELECTED_VEHICLES(state) {
        state.formData.selectedVehicles = [];
      },
      CLEAR_FORM_DATA(state) {
        state.formData = {
          calendarName: '',
          startDate: '',
          endDate: '',
          selectedZones: [],
          selectedVehicles: [],
          selectedDates: [],
          vehicleRates: {},
          // ... Restablecer otros campos si los hay
        };
      },
      SET_SELECTED_VEHICLES(state, vehicles) {
        state.formData.selectedVehicles = vehicles;
      },
    },

    actions: {

      saveFormData({ commit }, formData) {
        commit('UPDATE_FORM_DATA', formData);
      },
      updateVehicleRates({ commit }, { zoneId, vehicleId, rates }) {
        commit('SET_VEHICLE_RATES', { zoneId, vehicleId, rates });
      },
      
      async get_grouped_rates(_, {Authtoken, userCompany}) {
        try {

          const sessionUser = localStorage.getItem('user');
          const userType = JSON.parse(sessionUser).user.user_type;

          // console.log('userType: ', userType)
          // console.log('userCompany: ', userCompany)
          // console.log('Authtoken', Authtoken);
          // console.log('sessionUser', sessionUser);
          const API_URL = `${this.state.base_URL}grouped-rates`;

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany,
            'User-Type': userType
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      
      async get_all_TransportationRates(_, {Authtoken, userCompany}) {
        try {

          const sessionUser = localStorage.getItem('user');
          const userType = JSON.parse(sessionUser).user.user_type;

          // console.log('userType: ', userType)
          // console.log('userCompany: ', userCompany)
          // console.log('Authtoken', Authtoken);
          // console.log('sessionUser', sessionUser);
          const API_URL = `${this.state.base_URL}transportation-rates`;

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany,
            'User-Type': userType
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },


      async searchAptRates(_,{Authtoken, arrivalDate, departureDate, companyId, zonePerCompanyId, isArrival, isDeparture, vehiclePerCompany_ids, originZone, destinationZone}) {
        try {
          const API_URL = `${this.state.base_URL}zone-transportation-rates/search`;

          // Convertir el arreglo a una cadena JSON
          const vehicleIDSJson = JSON.stringify(vehiclePerCompany_ids);
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Rate-Arrival': arrivalDate,
              'Rate-Departure': departureDate,
              'Rate-Company': companyId,
              'Rate-Zone': zonePerCompanyId,
              'isArrival': isArrival,
              'isDeparture': isDeparture,
              'fromZone': originZone,
              'toZone': destinationZone,
              'vehicleIDS': vehicleIDSJson,
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },



      async getTransportationRatesInfo(_,{Authtoken, rateGroupID}) {
        try {
          const API_URL = `${this.state.base_URL}transportation-rates/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async create_TransportationRates(_, dataToUpdate) {
        try {
          const API_URL = `${this.state.base_URL}transportation-rates`;
          // console.log('JSON.stringify(dataToUpdate)', JSON.stringify(dataToUpdate));
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`,
              'Content-Type': 'application/json', // Indica que los datos son JSON
            },
            body: JSON.stringify(dataToUpdate) // Convierte los datos a una cadena JSON
          });
      
          const response = await res.json();
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async update_TransportationRates(_, dataToUpdate) {
        try {
          
          // console.log('dataToUpdate.rateID', dataToUpdate.rateID);
          const API_URL = `${this.state.base_URL}transportation-rates/${dataToUpdate.rateID}`;
          // console.log('API_URL', API_URL);

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }
          formData.append('_method', 'PUT'); // Suplantación del método
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
              // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
            },
            body: formData
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async deactivate_TransportationRates(_, { Authtoken, rateGroupID }) {
        try {
          
          // console.log('rateGroupID', rateGroupID)
          const API_URL = `${this.state.base_URL}transportation-rates/update-batch/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              transportationRate_status: 2
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async activate_TransportationRates(_, { Authtoken, rateGroupID }) {
        try {
          
          const API_URL = `${this.state.base_URL}transportation-rates/update-batch/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              transportationRate_status: 1
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async delete_TransportationRates(_, { Authtoken, rateGroupID }) {
        try {
          
          const API_URL = `${this.state.base_URL}transportation-rates/update-batch/${rateGroupID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              transportationRate_status: 3
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async delete_TransportationRates_permanently(_, { Authtoken, rateID }) {
        try {
          
          const API_URL = `${this.state.base_URL}transportation-rates/${rateID}`;
      
          const res = await fetch(API_URL, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    },
    
    getters: {
      getFormData(state) {
        return state.formData;
      },
    },
  }