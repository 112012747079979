// plugins/customersPlugin.js

export default {
    namespaced: true,
    actions: {
      // Tus acciones relacionadas con los usuarios
      async get_all_customers(_,Authtoken) {
        try {

          const API_URL = `${this.state.base_URL}clients`;
          const userBaseData = JSON.parse(localStorage.getItem('user'))
          const userType = userBaseData.user.user_type;
          const userCompany = userBaseData.company.company_id;
          // console.log('userType: ', userType)
          // console.log('userBaseData: ', userBaseData)
          // console.log('userCompany: ', userCompany)

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Type': userType
          };
          
          // Conditionally add User-Company header if userCompany exists
          if (userCompany) {
            headers['User-Company'] = userCompany;
          }
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async getUserInfo(_,{Authtoken, ClientID}) {
        try {
          const API_URL = `${this.state.base_URL}clients/${ClientID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
    }
  }