import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

const routes = [
  { 
    path: '/', 
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "apt-reservations-dashboard" */ '@/views/reservations/transportation/DashboardReservationsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/users', 
    name: 'users-dashboard',
    component: () => import(/* webpackChunkName: "users-dashboard" */ '@/views/users/DashboardUserView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/drivers', 
    name: 'drivers-dashboard',
    component: () => import(/* webpackChunkName: "drivers-dashboard" */ '@/views/drivers/DashboardDriverView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/companies', 
    name: 'companies-dashboard',
    component: () => import(/* webpackChunkName: "companies-dashboard" */ '@/views/companies/DashboardCompaniesView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/customers', 
    name: 'customers-dashboard',
    component: () => import(/* webpackChunkName: "customers-dashboard" */ '@/views/customers/DashboardCustomerView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/company', 
    name: 'company-dashboard',
    component: () => import(/* webpackChunkName: "company-dashboard" */ '@/views/company/DashboardCompanyView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/vehicles-global', 
    name: 'vehicles-global-dashboard',
    component: () => import(/* webpackChunkName: "vehicles-global-dashboard" */ '@/views/vehicles-global/DashboardVehiclesGlobalView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/fleet', 
    name: 'fleet-dashboard',
    component: () => import(/* webpackChunkName: "fleet-dashboard" */ '@/views/fleet/DashboardFleetView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/zones-global', 
    name: 'zones-global-dashboard',
    component: () => import(/* webpackChunkName: "zones-global-dashboard" */ '@/views/zones/DashboardZonesView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/destinations/transportation', 
    name: 'TransDesp-dashboard',
    component: () => import(/* webpackChunkName: "TransDesp-dashboard" */ '@/views/destinations/DashboardTransDespView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/zones', 
    name: 'zones-dashboard',
    component: () => import(/* webpackChunkName: "zones-dashboard" */ '@/views/zonesPerCompany/DashboardZonesPerCompanyView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/coupons', 
    name: 'coupons-dashboard',
    component: () => import(/* webpackChunkName: "coupons-dashboard" */ '@/views/coupons/DashboardCouponsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/marketing-channels', 
    name: 'channels-dashboard',
    component: () => import(/* webpackChunkName: "channels-dashboard" */ '@/views/mkt-channels/DashboardChannelsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/commercial-allies', 
    name: 'allies-dashboard',
    component: () => import(/* webpackChunkName: "allies-dashboard" */ '@/views/commercial-allies/DashboardAlliesView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/landing-pages/transportation', 
    name: 'transportation-landing-pages',
    component: () => import(/* webpackChunkName: "transportation-landing-pages" */ '@/views/landings/transportation/DashboardLandingPagesView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/sales-representatives', 
    name: 'sales-reps',
    component: () => import(/* webpackChunkName: "sales-reps" */ '@/views/sales-reps/DashboardSalesRepsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/fleet/addons', 
    name: 'transportation-addons-dashboard',
    component: () => import(/* webpackChunkName: "transportation-addons-dashboard" */ '@/views/addons/transportation/DashboardAddonsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/transportation/rates/apt/', 
    name: 'transportation-rates-dashboard',
    component: () => import(/* webpackChunkName: "transportation-rates-dashboard" */ '@/views/rates/transportation/DashboardRatesView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/transportation/rates/zones/', 
    name: 'transportation-zone-rates-dashboard',
    component: () => import(/* webpackChunkName: "transportation-rates-dashboard" */ '@/views/rates/zones/DashboardRatesView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/reservations/transportation', 
    name: 'apt-reservations-dashboard',
    component: () => import(/* webpackChunkName: "apt-reservations-dashboard" */ '@/views/reservations/transportation/DashboardReservationsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/itinerary', 
    name: 'itinerary-dashboard',
    component: () => import(/* webpackChunkName: "itinerary-dashboard" */ '@/views/trips/DashboardTripsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/reports', 
    name: 'reports-dashboard',
    component: () => import(/* webpackChunkName: "reports-dashboard" */ '@/views/reports/DashboardReportsView.vue'),
    meta: {
      authenticated: true,
      guest: false,
    }
  },
  { 
    path: '/login', 
    name: 'userLogin',
    component: () => import(/* webpackChunkName: "user-login" */ '@/auth/views/UserLoginView.vue'),
    meta: {
      authenticated: false,
      guest: true,
    }
  },
  { 
    path: '/forgot-password', 
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgot-password" */ '@/auth/views/ForgotPasswordView.vue'),
    meta: {
      authenticated: false,
      guest: true,
    }
  },
  {
    path: '/reset-password/:token', // Definimos :token como un parámetro dinámico
    name: 'resetPassword',
    component: () => import('@/auth/views/ResetPasswordView.vue'), // Importa tu componente
    meta: {
      authenticated: false,
      guest: true, // Opcional: define metadatos según tus necesidades
    },
  },
  {
    path: '/payment-link/:paymentId', // Definimos :token como un parámetro dinámico
    name: 'PaymentLink',
    component: () => import('@/views/reservations/PaymentLinkView.vue'), // Importa tu componente
    meta: {
      authenticated: false,
      guest: true, // Opcional: define metadatos según tus necesidades
    },
  },
  // Agregar una ruta para manejar las URL no declaradas
  {
    path: '/:pathMatch(.*)',
    redirect: { name: 'userLogin' }, // Redirigir a la página de inicio de sesión
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const hasToken = store.state.authStore.user.token;
  const needAuth = to.meta.authenticated;
  const isGuest = to.meta.guest; 

  if (needAuth && !hasToken) {
    // Si la ruta requiere autenticación y el usuario no está autenticado
    next({ name: 'userLogin' });
  } else if (to.name === 'PaymentLink') {
    // Permitir el acceso a la ruta PaymentLink si el usuario es un invitado
    next();
  } else if (!needAuth && hasToken && isGuest) {
    // Si la ruta no requiere autenticación y el usuario está autenticado
    next({ name: 'dashboard' });
  } else {
    // En cualquier otro caso, permitir la navegación a la ruta solicitada
    next();
  }
})


export default router
