<template>

<div>
  <div>
    <router-view/>
  </div>
</div>
</template>

<script>
// import { defineAsyncComponent } from 'vue';
  
  export default {
    components: {
      // DashboardView: defineAsyncComponent(() =>  import('@/views/DashboardView') )
    },
  };
  </script>

<style lang="scss">
#app {
  text-align: center;
}
</style>
