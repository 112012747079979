// plugins/userPlugin.js

export default {
  namespaced: true,
  actions: {
    // Tus acciones relacionadas con los usuarios
    async get_all_users(_,Authtoken) {
      try {

        const API_URL = `${this.state.base_URL}users`;
        const userBaseData = JSON.parse(localStorage.getItem('user'))
        const userType = userBaseData.user.user_type;
        const userCompany = userBaseData.company.company_id;
        // console.log('userType: ', userType)
        // console.log('userBaseData: ', userBaseData)
        // console.log('userCompany: ', userCompany)

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Authtoken}`,
          'User-Type': userType
        };
        
        // Conditionally add User-Company header if userCompany exists
        if (userCompany) {
          headers['User-Company'] = userCompany;
        }
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async getUserInfo(_,{Authtoken, UserID}) {
      try {
        const API_URL = `${this.state.base_URL}users/${UserID}`;
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async create_user(_, dataToUpdate) {
      try {
        
        const API_URL = `${this.state.base_URL}users`;

        // Crear un objeto FormData
        let formData = new FormData();
        
        // Agregar los datos y la imagen al objeto FormData
        for (const key in dataToUpdate) {
          if (key !== 'token' && dataToUpdate[key] !== null) {
            formData.append(key, dataToUpdate[key]);
          }
        }

        // for (const key in dataToUpdate) {
        //   if (key !== 'token' && dataToUpdate[key] !== null) {
        //     formData.append(key, dataToUpdate[key]);
        //     console.log(`Añadiendo al formData: ${key}:`, dataToUpdate[key]);
        //   }
        // }
  
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToUpdate.token}`
          },
          body: formData
        });
    
        const response = await res.json();
    
        console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async update_user(_, dataToUpdate) {
      try {
        
        const API_URL = `${this.state.base_URL}users/${dataToUpdate.userID}`;
        // console.log('ID ON function', dataToUpdate.userID);

        // Crear un objeto FormData
        let formData = new FormData();
        
        // Agregar los datos y la imagen al objeto FormData
        for (const key in dataToUpdate) {
          if (key !== 'token' && dataToUpdate[key] !== null) {
            formData.append(key, dataToUpdate[key]);
          }
        }

        // for (const key in dataToUpdate) {
        //   if (key !== 'token' && dataToUpdate[key] !== null) {
        //     formData.append(key, dataToUpdate[key]);
        //     console.log(`Añadiendo al formData: ${key}:`, dataToUpdate[key]);
        //   }
        // }

        formData.append('_method', 'PUT'); // Suplantación del método
  
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${dataToUpdate.token}`
            // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
          },
          body: formData
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async delete_user(_, { Authtoken, userID }) {
      try {
        
        const API_URL = `${this.state.base_URL}users/${userID}`;
    
        const res = await fetch(API_URL, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
          body: JSON.stringify({
            user_status: 3
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async delete_user_permanently(_, { Authtoken, userID }) {
      try {
        
        const API_URL = `${this.state.base_URL}users/${userID}`;
    
        const res = await fetch(API_URL, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          },
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
    
        return response;
      } catch (error) {
        console.error('Error during query:', error);
        throw new Error(error);
      }
    },

    async get_all_drivers(_,Authtoken) {
      try {

        const API_URL = `${this.state.base_URL}drivers`;
        const userBaseData = JSON.parse(localStorage.getItem('user'))
        const userType = userBaseData.user.user_type;
        const userCompany = userBaseData.company.company_id;
        // console.log('userType: ', userType)
        // console.log('userBaseData: ', userBaseData)
        // console.log('userCompany: ', userCompany)

        // Create headers object with common headers
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Authtoken}`,
          'User-Type': userType
        };
        
        // Conditionally add User-Company header if userCompany exists
        if (userCompany) {
          headers['User-Company'] = userCompany;
        }
    
        const res = await fetch(API_URL, {
          method: 'GET',
          headers: headers
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }
  
        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    // ... otras acciones relacionadas con los usuarios
  }
}