// plugins/authPlugin.js
export default {
  state: () => ({
    user: {},
    error: '' // Almacena errores
  }),
  namespaced: true,
  mutations: {
    setSession(state, payload) {
      state.user = payload;
      localStorage.setItem('user', JSON.stringify(payload));
    },
    clearSession(state) {
      state.user = {};
      // state.token = null;
      localStorage.removeItem('user');
    },
  },
  actions: {
    async login({ commit }, { email, pass }) {
      try {
        const API_URL = `${this.state.base_URL}login`;
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            user_email: email,
            user_password: pass
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }

        commit('setSession', response);
        return response;
      } catch (error) {
        // console.error('Error during login:', error);
        throw new Error(error);
      }
    },
    async logout({ commit }) {
      try {

        // Limpia el local storage o cualquier otra almacenamiento persistente
        // localStorage.removeItem('token');
        // sessionStorage.clear();  // Si estás usando sessionStorage también
    
        commit('clearSession');

        // window.location.reload();
        // console.log('Logout successful'); // Agrega un mensaje de registro para verificar si se ejecuta esta acción
      } catch (error) {
        throw new Error(error);
      }
    },
    async user_exists(_, email) {
      try {
        const API_URL = `${this.state.base_URL}send-reset-link`;
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }

        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
    async reset_user_password(_, {password, password_confirmation, token}) {
      try {
        const API_URL = `${this.state.base_URL}change-password`;
    
        const res = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            password: password,
            password_confirmation: password_confirmation,
            token: token,
          })
        });
    
        const response = await res.json();
    
        // console.log('Response from server:', response);
    
        if ('errors' in response) {
          return response.errors;
        }

        return response;
      } catch (error) {
        // console.error('Error during query:', error);
        throw new Error(error);
      }
    },
  }
};
