// plugins/AddonsBookedPlugin.js

export default {
    namespaced: true,
    actions: {

      async send_suggestions(_, dataToUpdate) {
        try {
          
          const API_URL = `${this.state.base_URL}suggestions`;

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }

          // for (const key in dataToUpdate) {
          //   if (key !== 'token' && dataToUpdate[key] !== null) {
          //     formData.append(key, dataToUpdate[key]);
          //     console.log(`Añadiendo al formData: ${key}:`, dataToUpdate[key]);
          //   }
          // }
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
            },
            body: formData
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },

    }
  }