// plugins/CouponsPlugin.js

export default {
    namespaced: true,
    actions: {


      // Tus acciones relacionadas con los empresas
      async get_all_Coupons(_, {Authtoken, userCompany}) {
        try {

          // console.log('Authtoken', Authtoken);
          // console.log('sessionUser', sessionUser);
          const API_URL = `${this.state.base_URL}coupons`;
          // console.log('userType: ', userType)

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`,
            'User-Company': userCompany
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },


      async getCouponsInfo(_,{Authtoken, couponID}) {
        try {
          const API_URL = `${this.state.base_URL}coupons/${couponID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },

      async findCoupon(_,{Authtoken, couponCode}) {
        try {
          const API_URL = `${this.state.base_URL}find-coupon/${couponCode}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },


      async create_Coupons(_, dataToUpdate) {
        try {
          
          const API_URL = `${this.state.base_URL}coupons`;

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }

          // for (const key in dataToUpdate) {
          //   if (key !== 'token' && dataToUpdate[key] !== null) {
          //     formData.append(key, dataToUpdate[key]);
          //     console.log(`Añadiendo al formData: ${key}:`, dataToUpdate[key]);
          //   }
          // }
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
            },
            body: formData
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },


      async update_Coupons(_, dataToUpdate) {
        try {
          
          // console.log('dataToUpdate.couponID', dataToUpdate.couponID);
          const API_URL = `${this.state.base_URL}coupons/${dataToUpdate.couponID}`;
          // console.log('API_URL', API_URL);

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }
          formData.append('_method', 'PUT'); // Suplantación del método
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
              // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
            },
            body: formData
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },


      async delete_Coupons(_, { Authtoken, couponID }) {
        try {
          
          const API_URL = `${this.state.base_URL}coupons/${couponID}`;
      
          const res = await fetch(API_URL, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    }
  }