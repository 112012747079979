// plugins/billingPlugin.js

export default {
    namespaced: true,
    actions: {
      // Tus acciones relacionadas con los empresas
      async getPaymentMethods(_,Authtoken) {
        try {
          const API_URL = `${this.state.base_URL}payment-methods`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },

      async getSubscriptions(_,Authtoken) {
        try {
          const API_URL = `${this.state.base_URL}subscriptions`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },

      async getInvoices(_,Authtoken) {
        try {
          const API_URL = `${this.state.base_URL}invoices`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },

      async createSubscription(_, {Authtoken}) {
        try {
            const API_URL = `${this.state.base_URL}subscribe`;
    
            // Hacer la solicitud POST sin enviar planId
            const res = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${Authtoken}`
                }
            });
    
            const response = await res.json();
            console.log('Response from server:', response);
    
            if (response.errors) {
                console.error('Errors returned from server:', response.errors);
                return response.errors;
            }
    
            return response;
        } catch (error) {
            console.error('Error during query:', error);
            throw new Error('Error creating subscription: ' + error.message);
        }
    },    
      

      async createPaymentMethod(_, {Authtoken, dataToUpdate}) {
        try {
          
          const API_URL = `${this.state.base_URL}add-payment-method`;

          console.log('Auth Token: ', Authtoken);


          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: formData
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    }
  }