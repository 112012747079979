// plugins/companiesPlugin.js

export default {
    namespaced: true,
    actions: {
      // Tus acciones relacionadas con los empresas
      async get_all_companies(_,Authtoken) {
        try {

          const API_URL = `${this.state.base_URL}companies`;
          // console.log('userType: ', userType)

          // Create headers object with common headers
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Authtoken}`
          };
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: headers
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
    
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async getCompanyInfo(_,{Authtoken, CompanyID}) {
        try {
          const API_URL = `${this.state.base_URL}companies/${CompanyID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async getCompanyStatus(_,{Authtoken, userID}) {
        try {
          const API_URL = `${this.state.base_URL}companies/status/${userID}`;
      
          const res = await fetch(API_URL, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          // console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async create_company(_, dataToUpdate) {
        try {
          
          const API_URL = `${this.state.base_URL}companies`;

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }

          // for (const key in dataToUpdate) {
          //   if (key !== 'token' && dataToUpdate[key] !== null) {
          //     formData.append(key, dataToUpdate[key]);
          //     console.log(`Añadiendo al formData: ${key}:`, dataToUpdate[key]);
          //   }
          // }
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
            },
            body: formData
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async update_company(_, dataToUpdate) {
        try {
          
          const API_URL = `${this.state.base_URL}companies/${dataToUpdate.companyID}`;
          // console.log('ID ON function', dataToUpdate.companyID);

          // Crear un objeto FormData
          let formData = new FormData();
          
          // Agregar los datos y la imagen al objeto FormData
          for (const key in dataToUpdate) {
            if (key !== 'token' && dataToUpdate[key] !== null) {
              formData.append(key, dataToUpdate[key]);
            }
          }
          formData.append('_method', 'PUT'); // Suplantación del método
    
      
          const res = await fetch(API_URL, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${dataToUpdate.token}`
              // No establecer 'Content-Type' aquí, el navegador lo hará automáticamente
            },
            body: formData
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async deactivate_company(_, { Authtoken, companyID }) {
        try {
          
          const API_URL = `${this.state.base_URL}companies/${companyID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              company_status: 2
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async activate_company(_, { Authtoken, companyID }) {
        try {
          
          const API_URL = `${this.state.base_URL}companies/${companyID}`;
      
          const res = await fetch(API_URL, {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
            body: JSON.stringify({
              company_status: 1
            })
          });
      
          const response = await res.json();
      
          // console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      async delete_company_permanently(_, { Authtoken, companyID }) {
        try {
          
          const API_URL = `${this.state.base_URL}companies/${companyID}`;
      
          const res = await fetch(API_URL, {
            method: 'DELETE',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Authtoken}`
            },
          });
      
          const response = await res.json();
      
          console.log('Response from server:', response);
      
          if ('errors' in response) {
            return response.errors;
          }
      
          return response;
        } catch (error) {
          console.error('Error during query:', error);
          throw new Error(error);
        }
      },
      // ... otras acciones relacionadas con los empresas
    }
  }