import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap'; // Esto importará Bootstrap JavaScript
import '@/assets/styles/styles.scss';

// En la inicialización de tu aplicación Vue

const sessionUser = localStorage.getItem('user');
const user = JSON.parse(sessionUser);

// console.log('Session User on Main', user);
if (user) {
  store.commit('authStore/setSession', user);
}

const app = createApp(App)
app.config.globalProperties.$sessionUser = user;

app.use(store)
app.use(router)
app.mount('#app')
